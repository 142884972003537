import React, { useContext } from 'react';
import { Link } from 'react-scroll';
import Sticky from 'react-stickynode';
import AuthContext from '../context/AuthContext';

const OnepageMenu = () => {
  const { user, logoutUser } = useContext(AuthContext);
  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className="header_area">
        <nav className={`navbar navbar-expand-lg menu_one menu_four`}>
          <div className={`container`}>
            <a className={`navbar-brand sticky_logo`} href="/">
              <img src={require('../img/logoheader.png')} alt="" />
              <img src={require('../img/logoheader2.png')} alt="logo" />
            </a>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className={`navbar-nav menu ml-auto w_menu`}>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Ana Sayfa
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="features"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1000}
                  >
                    Kalori Hesaplama
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="progress_bar_area"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1000}
                  >
                    Yapay Zeka
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="testimonial"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1000}
                  >
                    Testimonial
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="get_started_area"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1000}
                  >
                    Telefon Uygulaması
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="testimonial"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1000}
                  >
                    Geliştiriciler
                  </Link>
                </li>
              </ul>
              {user ? (
                <button
                  className={`btn_get btn_hover btn_get_radious menu_custfive`}
                  onClick={logoutUser}
                >
                  Çıkış Yap
                </button>
              ) : (
                <a
                  className={`btn_get btn_hover btn_get_radious menu_custfive`}
                  href="/SignIn"
                >
                  Giriş Yap
                </a>
              )}
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  );
};

export default OnepageMenu;
