import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import AuthContext from '../context/AuthContext';

const SignUpForm = () => {
  const {
    register,
    formState: { errors },
  } = useForm();

  const { registerUser } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const password2 = e.target.password2.value;
    registerUser(username, email, password, password2);
  };

  return (
    <section className="sign_in_area bg_color sec_pad">
      <div className="container">
        <div className="sign_info">
          <div className="row">
            <div className="col-lg-5">
              <div className="sign_info_content">
                <h3 className="f_p f_600 f_size_24 t_color3 mb_40">
                  Zaten bir hesabınız var mı?
                </h3>
                <h2 className="f_p f_400 f_size_30 mb-30">
                  Şimdi giriş yap ve
                  <br /> sana özel hazırlanmış <br />
                  <span className="f_700">muhteşem</span> içeriklere eriş!
                </h2>
                <ul className="list-unstyled mb-0">
                  <li>
                    <i className="ti-check"></i> Tüm İçeriklere Sınırsız Erişim
                  </li>
                  <li>
                    <i className="ti-check"></i> Ücretsiz Vücut Analizi
                  </li>
                  <li>
                    <i className="ti-check"></i> Kişiye Özel Programlar ve Daha
                    Fazlası
                  </li>
                </ul>
                <a href="/SignIn" className="btn_three sign_btn_transparent">
                  Giriş Yap
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="login_info">
                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Kayıt Ol</h2>
                <form
                  action="#"
                  className="login-form sign-in-form"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group text_box">
                    <label className="f_p text_c f_400">Kullanıcı Adı</label>
                    <input
                      defaultValue=""
                      type="text"
                      placeholder="Kullanıcı Adınız"
                      maxLength={24}
                      {...register('username', {
                        required: 'Bu alan gereklidir.',
                      })}
                    />
                    {errors.username && <span>{errors.username.message}</span>}
                  </div>
                  <div className="form-group text_box">
                    <label className="f_p text_c f_400">E-posta</label>
                    <input
                      type="text"
                      placeholder="email@gmail.com"
                      {...register('email', {
                        required: 'Bu alan gereklidir.',
                      })}
                    />
                    {errors.email && <span>{errors.email.message}</span>}
                  </div>
                  <div className="form-group text_box">
                    <label className="f_p text_c f_400">Şifre</label>
                    <input
                      type="password"
                      placeholder="******"
                      {...register('password', {
                        required: 'Bu alan gereklidir.',
                        minLength: 8,
                      })}
                    />
                    {errors.password && <span>{errors.password.message}</span>}
                  </div>
                  <div className="form-group text_box">
                    <label className="f_p text_c f_400">Şifre (Tekrar)</label>
                    <input
                      type="password"
                      placeholder="******"
                      {...register('password2', {
                        required: 'Bu alan gereklidir.',
                        minLength: 8,
                      })}
                    />
                    {errors.password2 && (
                      <span>{errors.password2.message}</span>
                    )}
                  </div>
                  <div className="extra mb_20">
                    <div className="checkbox remember">
                      <label>
                        <input
                          type="checkbox"
                          {...register('terms', {
                            required: 'Bu alan gereklidir.',
                          })}
                        />{' '}
                        Bu web sitesinin hüküm ve koşullarını kabul ediyorum.
                      </label>
                      {errors.terms && (
                        <span>
                          <br />
                          {errors.terms.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button type="submit" className="btn_three">
                      Kayıt Ol
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignUpForm;
