import React, { useState } from 'react';
import styles from '../assets/styles.module.css';

const AIForm = () => {
  const [result, setResult] = useState('');
  async function postJSON(
    gender,
    age,
    weight,
    height,
    bmi,
    mass_nonfat_kg,
    mass_nonfat_pc,
    fat_kg,
    fat_pc,
    liquid_kg,
    liquid_pc,
    bmr,
    activity,
    pal
  ) {
    try {
      const response = await fetch('https://aidietplanner.com/apiv2/dietset/', {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          gender,
          age,
          weight,
          height,
          bmi,
          mass_nonfat_kg,
          mass_nonfat_pc,
          fat_kg,
          fat_pc,
          liquid_kg,
          liquid_pc,
          bmr,
          activity,
          pal,
        }),
      });

      const result = await response.json();
      result && setResult(result);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const el = e.target;
    postJSON(
      el.gender.value,
      el.age.value,
      el.weight.value,
      el.height.value,
      el.bmi.value,
      el.mass_nonfat_kg.value,
      el.mass_nonfat_pc.value,
      el.fat_kg.value,
      el.fat_pc.value,
      el.liquid_kg.value,
      el.liquid_pc.value,
      el.bmr.value,
      el.activity.value,
      el.pal.value
    );
  };
  return (
    <section className="progress_bar_area">
      <div className="sec_title mb_70 wow fadeInUp" data-wow-delay="0.4s">
        <h2 className="f_p f_size_30 l_height40 f_600 t_color text-center">
          Kalorinizi Yapay Zeka <br /> Hesaplasın!
        </h2>
      </div>
      <div className={styles.main}>
        <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
          <div className={styles.frame20}>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="gender"
              >
                Cinsiyet
              </label>
              <select
                className={'ai_input pl_10'}
                id="gender"
                name="gender"
                required
              >
                <option value={''}>Lütfen seçim yapın</option>
                <option value={2}>Kadın</option>
                <option value={1}>Erkek</option>
              </select>
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="age"
              >
                Yaş
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="age"
                name="age"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="weight"
              >
                Kilo
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="weight"
                name="weight"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="height"
              >
                Boy
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="height"
                name="height"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="bmi"
              >
                BMI
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="bmi"
                name="bmi"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="mass_nonfat_kg"
              >
                Yağsız kütle kg
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="mass_nonfat_kg"
                name="mass_nonfat_kg"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="mass_nonfat_pc"
              >
                Yağsız kütle %
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="mass_nonfat_pc"
                name="mass_nonfat_pc"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="fat_kg"
              >
                Yağ kg
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="fat_kg"
                name="fat_kg"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="fat_pc"
              >
                Yağ %
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="fat_pc"
                name="fat_pc"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="liquid_kg"
              >
                Sıvı kg
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="liquid_kg"
                name="liquid_kg"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="liquid_pc"
              >
                Sıvı %
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="liquid_pc"
                name="liquid_pc"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="bmr"
              >
                BMR kcal
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="bmr"
                name="bmr"
                required
              />
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="activity"
              >
                Fiziksel aktivite
              </label>
              <select
                className={'ai_input pl_10'}
                id="activity"
                name="activity"
                required
              >
                <option value={''}>Lütfen seçim yapın</option>
                <option value={1}>Evet</option>
                <option value={2}>Hayır</option>
              </select>
            </div>
            <div>
              <label
                className={'f_size_20 f_os f_600 l_height28'}
                htmlFor="pal"
              >
                Pal
              </label>
              <input
                className={'ai_input pl_10'}
                type="text"
                id="pal"
                name="pal"
                required
              />
            </div>
          </div>
          <button className={styles.button} type="submit">
            Kaydet
          </button>
        </form>
        {result && (
          <div className="ai_result" id="ai_result">
            <p className="ai_result_text">Enerji ihtiyacınız: </p>
            <p className="f_size_72 f_os f_800 mt_40 mb_40 ml_10 mr_10">
              {result.result.toFixed(2)}
            </p>
            <p className="ai_result_text">kkal</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default AIForm;
