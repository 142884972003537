import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const BMRForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [bmrResult, setBmrResult] = useState(0);

  const onSubmit = (data) => {
    if (data['gender'] === 'male') {
      if (data['activity'] === 'little') {
        setBmrResult(
          (66.5 +
            13.75 * parseInt(data['weight']) +
            5.003 * parseInt(data['height']) -
            6.75 * parseInt(data['age'])) *
            1.2
        );
      } else if (data['activity'] === 'light') {
        setBmrResult(
          (66.5 +
            13.75 * parseInt(data['weight']) +
            5.003 * parseInt(data['height']) -
            6.75 * parseInt(data['age'])) *
            1.375
        );
      } else if (data['activity'] === 'moderate') {
        setBmrResult(
          (66.5 +
            13.75 * parseInt(data['weight']) +
            5.003 * parseInt(data['height']) -
            6.75 * parseInt(data['age'])) *
            1.55
        );
      } else if (data['activity'] === 'hard') {
        setBmrResult(
          (66.5 +
            13.75 * parseInt(data['weight']) +
            5.003 * parseInt(data['height']) -
            6.75 * parseInt(data['age'])) *
            1.725
        );
      }
    } else {
      if (data['activity'] === 'little') {
        setBmrResult(
          (655.1 +
            9.563 * parseInt(data['weight']) +
            1.85 * parseInt(data['height']) -
            4.676 * parseInt(data['age'])) *
            1.2
        );
      } else if (data['activity'] === 'light') {
        setBmrResult(
          (655.1 +
            9.563 * parseInt(data['weight']) +
            1.85 * parseInt(data['height']) -
            4.676 * parseInt(data['age'])) *
            1.375
        );
      } else if (data['activity'] === 'moderate') {
        setBmrResult(
          (655.1 +
            9.563 * parseInt(data['weight']) +
            1.85 * parseInt(data['height']) -
            4.676 * parseInt(data['age'])) *
            1.55
        );
      } else if (data['activity'] === 'hard') {
        setBmrResult(
          (655.1 +
            9.563 * parseInt(data['weight']) +
            1.85 * parseInt(data['height']) -
            4.676 * parseInt(data['age'])) *
            1.725
        );
      }
    }
  };

  return (
    <section className="container">
      <div className="sign_info">
        <div className="row">
          <div className="col-lg-7">
            <div className="login_info">
              <form className="apply_form login-form sign-in-form">
                <div className="form-group text_box">
                  <input
                    defaultValue=""
                    type="text"
                    placeholder="Ağırlık (kg)"
                    maxLength={3}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register('weight', {
                      required: 'Bu alan gereklidir.',
                    })}
                  />
                  {errors.weight && <span>{errors.weight.message}</span>}
                </div>
                <div className="form-group text_box">
                  <input
                    defaultValue=""
                    type="text"
                    placeholder="Uzunluk (cm)"
                    maxLength={3}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register('height', {
                      required: 'Bu alan gereklidir.',
                    })}
                  />
                  {errors.height && <span>{errors.height.message}</span>}
                </div>
                <div className="form-group text_box">
                  <input
                    defaultValue=""
                    type="text"
                    placeholder="Yaş"
                    maxLength={3}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register('age', {
                      required: 'Bu alan gereklidir.',
                    })}
                  />
                  {errors.age && <span>{errors.age.message}</span>}
                </div>
                <div className="form-group text_box">
                  <select
                    className="selectpickers"
                    {...register('gender', {
                      required: 'Bu alan gereklidir.',
                    })}
                  >
                    <option value="">Cinsiyet</option>
                    <option value="male">Erkek</option>
                    <option value="female">Kadın</option>
                  </select>
                  {errors.gender && <span>{errors.gender.message}</span>}
                </div>
                <div className="form-group text_box">
                  <select
                    className="selectpickers"
                    {...register('activity', {
                      required: 'Bu alan gereklidir.',
                    })}
                  >
                    <option value="">Fiziksel aktiflik</option>
                    <option value="little">Az/Hiç hareketli</option>
                    <option value="light">
                      Hafif hareketli haftada 1-2 egzersiz
                    </option>
                    <option value="moderate">
                      Orta hareketli haftada 2-3 egzersiz
                    </option>
                    <option value="hard">
                      Aşırı hareketli haftada 4-5 egzersiz
                    </option>
                  </select>
                  {errors.activity && <span>{errors.activity.message}</span>}
                </div>
                <button className="btn_three" onClick={handleSubmit(onSubmit)}>
                  Hesapla
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-5">
            {bmrResult > 0 && (
              <div className="bmr_result_box">
                <p className="f_size_30">Günlük ihtiyacınız:</p>
                <p className="bmr_result">{bmrResult.toFixed()}</p>
                <p className="f_size_30">kkal</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BMRForm;
