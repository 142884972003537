import React from 'react';
import OnepageMenu from '../components/OnepageMenu';
import AppBanner from '../components/Banner/AppBanner';
import StartupFeatures from '../components/Features/StartupFeatures';
import AIForm from '../components/AIForm';
import AppGetstarted from '../components/AppGetstarted';
import AppTestimonial from '../components/Testimonial/AppTestimonial';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

export const Home = () => (
  <div className="body_wrapper">
    <OnepageMenu
      slogo="sticky_logo"
      mClass="menu_four"
      nClass="w_menu"
      hbtnClass="btn_get_radious menu_custfive"
    />
    <AppBanner />
    <StartupFeatures />
    <AIForm />
    <AppGetstarted />
    <AppTestimonial />
    <FooterTwo FooterData={FooterData} />
  </div>
);
