import React from 'react';
import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const ProtectedPage = () => {
  const [res, setRes] = useState('');
  const api = useAxios();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/test/');
        setRes(response.data.response);
      } catch {
        setRes('Something went wrong');
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Sign In"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      />
      <h1>Projected Page</h1>
      <p>{res}</p>
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};

export default ProtectedPage;
