import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import AuthContext from '../context/AuthContext';

const SignInFrom = () => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const { loginUser } = useContext(AuthContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;
    username.length > 0 && loginUser(username, password);
  };
  return (
    <section className="sign_in_area bg_color sec_pad">
      <div className="container">
        <div className="sign_info">
          <div className="row">
            <div className="col-lg-5">
              <div className="sign_info_content">
                <h3 className="f_p f_600 f_size_24 t_color3 mb_40">
                  Burada yeni misin?
                </h3>
                <h2 className="f_p f_400 f_size_30 mb-30">
                  Şimdi kaydol ve
                  <br /> sana özel hazırlanmış <br />
                  <span className="f_700">muhteşem</span> içeriklere eriş!
                  <br />{' '}
                </h2>
                <ul className="list-unstyled mb-0">
                  <li>
                    <i className="ti-check"></i> Tüm İçeriklere Sınırsız Erişim
                  </li>
                  <li>
                    <i className="ti-check"></i> Ücretsiz Vücut Analizi
                  </li>
                  <li>
                    <i className="ti-check"></i> Kişiye Özel Programlar ve Daha
                    Fazlası
                  </li>
                </ul>
                <a href="/SignUp" className="btn_three sign_btn_transparent">
                  Kayıt Ol
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="login_info">
                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">
                  Giriş Yap
                </h2>
                <form
                  action="/#"
                  className="login-form sign-in-form"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group text_box">
                    <label className="f_p text_c f_400">
                      E-posta ya da Kullanıcı Adı
                    </label>
                    <input
                      defaultValue=""
                      type="text"
                      placeholder="email@gmail.com"
                      {...register('username', {
                        required: 'Bu alan gereklidir.',
                      })}
                    />
                    {errors.username && <span>{errors.username.message}</span>}
                  </div>
                  <div className="form-group text_box">
                    <label className="f_p text_c f_400">Şifre</label>
                    <input
                      defaultValue=""
                      type="password"
                      placeholder="******"
                      {...register('password', {
                        required: 'Bu alan gereklidir.',
                      })}
                    />
                    {errors.password && <span>{errors.password.message}</span>}
                  </div>
                  <div className="extra mb_20">
                    <div className="checkbox remember">
                      <label>
                        <input type="checkbox" />
                        Oturumumu açık tut
                      </label>
                    </div>

                    <div className="forgotten-password">
                      <a href="/#">Parolanızı mı unuttunuz?</a>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button type="submit" className="btn_three">
                      Giriş Yap
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignInFrom;
